.table-container {
    width: 100%;
    min-width: 1160px;
    overflow-x: auto; /* Enable horizontal scrolling */
    margin: 20px 0;
    position: relative;
}

.table-container.loading {
    opacity: 0.5;
}

.loading-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: none;
    z-index: 10;
}

.dynamic-table {
    width: 100%; /* Ensure the table takes the full width of the container */
    border-collapse: collapse;
    min-width: 100%; /* Make sure the table is at least as wide as the container */
    table-layout: fixed;
}

.dynamic-table th,
.dynamic-table td {
    padding: 8px 12px; /* Adjust padding to control row height */
    text-align: left;
    vertical-align: middle; /* Vertically center the text */
    border-bottom: 1px solid #ddd;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate text with ellipsis if it overflows */
    font-size: 14px; /* Smaller font size for table text */
}

.dynamic-table th {
    background-color: #fdf2e9;
    font-weight: bold;
    font-size: 13px; /* Slightly reduced header font size */
    padding: 6px 10px; /* Slightly reduced padding for header cells */
    position: relative; /* Allows positioning of the sort arrow */
}

.sort-arrow {
    font-size: 11px;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
}

.dynamic-table td {
    background-color: #f9f9f9;
}

.dynamic-table tr:nth-child(even) td {
    background-color: #ffffff;
}

.empty-data {
    text-align: center;
    color: #777;
    padding: 20px;
    font-size: 14px; /* Ensure consistent font size for empty data message */
}

/* Status Dots CSS */
.status-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    /*margin-left: 20px;*/
}

.status-dot.online {
    background-color: var(--green);
}

.status-dot.offline {
    background-color: var(--red);
}

.status-dot.valid {
    background-color: var(--green);
}

.status-dot.expiring-soon {
    background-color: var(--yellow);
}

.status-dot.expiring-very-soon {
    background-color: orange;
}

.status-dot.expired {
    background-color: var(--red);
}

.active.yes {
    color: var(--green);
    font-size: 16px;
}

.active.no {
    color: var(--red);
    font-size: 13px;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    flex-wrap: wrap;
}

.pagination-button,
.pagination-number {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background-color: #f5f5f5;
}

.pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-number.active {
    background-color: #F05A28;
    color: white;
}

.extendable-row {
    cursor: pointer;
}

.extended-row {
    background-color: #f9f9f9;
}

.extended-content {
    padding: 10px;
    border-top: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.extended-item {
    min-width: 150px;
}

.filter_tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    border: none;
    border-radius: 5px;
    padding: 6px 8px;
    height: 12px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    background-color: #F05A28;
    color: var(--white);
    width: fit-content;
}

.close-button {
    margin-left: 8px;
    border: 1px solid var(--black);
    border-radius: 6px;
    background-color: #F05A28;
    color: var(--black);
    margin-right: -5px;
    font-weight: bold;
    padding: 0;
}

.close-button:hover {
    background-color: #F05A28;
}

.close-button:focus {
    background-color: #F05A28;
}

.earnings-filters {
    padding: 0 4px;
    display: flex;
    flex-direction: column;
}

.sorting-column-header{
    width: 40px;
}