.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 28px;
    height: 48px;
    background: #7BAE37;
    box-shadow: 0 0.5pt 1pt #00000029;
}
.button-container:hover {
    background: #fff;
    box-shadow: 0 1.5pt 3pt #00000029;
}
.button-container:hover .button {
    color: #000;
    font-weight: 600;
}

.save_button {
    background-color: var(--yellow);
    border-color: var(--yellow);
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
}

.button-confirm {
    width: 212px;
    height: 48px;
}
.button-confirm .button p {
    font-size: 18px;
}
.button-default {
    width: 212px;
    height: 48px;
}
.button-default .button p {
    font-size: 14px;
}
.button-login {
    width: 85%;
    margin: 24px 0;
}
.button-add {
    background: #fff;
    width: 330px;
}
.button-add .icon-add-circle {
    fill: #000;
}
.button-add .button {
    color: #000;
}

.button-add:hover {
    background: #7BAE37;
}
.button-add:hover .button {
    color: #fff;
    font-weight: 600;
}
.button-add:hover .icon-add-circle {
    fill: #fff;
}

.button-info {
    color: #000;
    padding: 0 24px;
    border: 2px solid #7BAE37;
    background: transparent;
    zoom: 0.68;
    margin-top: -8px;
    margin-bottom: 12px;
}

.button-info p {
    color: #7BAE37;
}

.button-delete {
    background: transparent;
    width: fit-content;

}
.button-delete .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-right: 12px;
}
.button-delete:hover {
    background: transparent;
    box-shadow: none;
}
.button-delete:hover .button p {
    font-weight: bold;
}

.button-selection-filter {
    display: flex;
    flex-direction: row;
}

.button-selection-users {
    display: flex;
    flex-direction: row;

    z-index: 999;
    position: relative;
    right: 198px;
    top: 25px;
    zoom: 0.85;
    width: 100%;
    justify-content: flex-end;
}

.button-selection {
    background: #fff;
}
.button-selection .button p {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
}
.button-selection:hover, .button-selected {
    background: #7BAE37;
    box-shadow: 0 3px 6px #00000029;
}
.button-selection:hover .button p, .button-selected .button p {
    color: #fff;
}

.button-container.button-default.button-selection.button-disabled {
    opacity: 0.5;
    background: white !important;
}

.button-confirm {
    width: 212px;
    height: 48px;
    margin: 0 20px;
}
.button-reject {
    width: 140px;
}
.button-reject {
    background: transparent;
}
.button-reject .button {
    color: #707070;
}
.button-reject:hover {
    box-shadow: none;
}

.button-upload {
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.button-upload .button p {
    font-size: 14px;
    color: #fff;
}
.button-upload:hover .button p {
    color: #0A0A0A;
}
.button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
}
.button > p {
    font-weight: bold;
}
.button-container .icon {
    margin-right: 8px;
}
.button-disabled.button-container.button-default {
    opacity: 0.6;
}

.button-disabled.button-container.button-default:hover {
    background-color: #7BAE37;
    cursor: unset;
    box-shadow: unset;
}

.button-disabled:hover .button {
    color: #fff;
}

.l10n-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 48px;
}

.l10n-button-group > div.button-selection {
    margin: 0 20px;
}

.button-primary {
    width: 212px;
    height: 48px;
}
.button-secondary {
    background-color: white;
    color: darkorange;
}

.toggle-buttons {
    display: flex;
}

.toggle-buttons .button-container {
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.yellow-border {
    border-width: 1px;
    border-color: var(--yellow);
    border-style: solid;
}


.white-border {
    border-width: 1px;
    border-color: var(--white);
    border-style: solid;
}

.cancel-button {
    background-color: var(--red);
    color: var(--white);
    border: none;
    margin-left: 0;
    cursor: pointer;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    align-content: center;
}

.taxi {
    background-color: var(--yellow);
    color: var(--white);
    border: none;
    margin-left: 0;
    cursor: pointer;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    align-content: center;
}

.remove-button {
    background-color: var(--red);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.swap-button {
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.button-toggle_layers {
    height: 26px;
    border-radius: 0;
    margin: 0;
}

.button-toggle_layers > .button > p {
    font-size: 12px;
}

.button-toggle_layers.selected {
    background-color: var(--yellow);
}

.button-toggle_layers.selected .button > p {
    color: var(--black);
}

.button-toggle_layers.default {
    background-color: rgb(125, 125, 125);
}

.vehicle_selection {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 34px;
    text-align: center;
}

.create_order {
    border: none;
    padding: 6px 20px;
    height: 21px;
    margin-top: -4px;
    margin-left: 6px;
    width: fit-content;
    cursor: pointer;
    border-radius: 4px;
}

.cancel_order {
    border: none;
    color: var(--white);
    background-color: var(--red);
    margin-top: 10px;
    height: 36px;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.finance_button {
    border: none;
    background-color: #F05A28;
    padding: 4px 0px;
    height: 24px;
    margin-left: 10px;
    margin-top: 10px;
    width: 120px;
    cursor: pointer;
    font-size: 12px;
}

.finance_button:hover {
    background-color: #F05A28;
    border-color: #F05A28;
    color: var(--black);
}

.rounded_square {
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 2px 4px;
    height: 14px;
    width: fit-content;
    cursor: pointer;
    font-size: 12px;
}

.red_inverted {
    background-color: var(--white);
    border-color: var(--red);
    width: 50px;
    p{
        color: var(--red);
    }
    &:hover{
        background-color: var(--red);
        border-color: var(--red);
        p{
            color: var(--white);
        }
    }
}

.green_inverted {
    background-color: var(--white);
    border-color: var(--green);
    width: 50px;
    p{
        color: var(--green);
    }
    &:hover{
        background-color: var(--green);
        border-color: var(--green);
        p{
            color: var(--white);
        }
    }
}

.red {
    background-color: var(--red);
    border-color: var(--red);
}

.green {
    background-color: var(--green);
    border-color: var(--green);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.toggle-labels-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-left: 10px;
    box-shadow: none;
}