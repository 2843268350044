/* General styles */
.App {
  text-align: center;
  overflow: hidden;
}

a {
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,800');

#root {
  height: 100vh;
}
html {
  scroll-behavior: smooth;
  /*
  -webkit-transform: translate3d(0, 0, 0);
   */
  -webkit-overflow-scrolling: touch;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #cfcfcf;
}

a, p, textarea, input, ul li, div {
  font-family: 'Open Sans', sans-serif;
}

.p-submit {
  color: white;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0px;
  background-color: #29ABE2;
  border-radius: 20px;
  padding: 0px 24px 0px 24px;
  border: 1px solid #AFD1E8;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}
p.large {
  font-size: 2.25em;
  height: 70px;
  border-radius: 25px;
  min-width: 170px;
  max-width: fit-content;
}
.p-large {
  color: black;
  font-size: 1.5em;
  margin: 0px;
  font-weight: 300;
}
.p-large:hover {
  font-weight: 400 !important;
}
.p-tab {
  color: black;
  font-size: 1.2em;
  margin: 0px;
  font-weight: 300;
}
.p-tab:hover {
  font-weight: 400 !important;
  margin-left: -1px;
  letter-spacing: -0.35px !important;
}
.p-header {
  color: white;
  font-size: 1.25em;
  font-weight: 400;
  margin: 0px;
}
.p-header.black {
  color: black;
  font-weight: 300;
}
.p-header.blue {
  color: #29ABE2;
  font-weight: 400;
}
.p-medium {
  color: black;
  font-size: 1em;
  margin: 0px;
  font-weight: 300;
}

.p-small {
  color: black;
  font-size: 0.85em;
  font-weight: 400;
  width: fit-content;
}
.p-landing {
  color: white;
  font-size: 3.25em;
  font-weight: bolder;
  margin: 0px;
  line-height: 1.6em;
}
.p-review {
  color: #29ABE2;
  font-size: 2.7em;
  font-weight: 800;
}

.p-desc, .p-info, .p-price {
  color: black;
  font-size: 0.78em;
  letter-spacing: 0.008em;
  font-weight: 300;
  width: fit-content;
  margin: 0;
  padding: 5px 0;
}
.p-desc.address {
  font-weight: 300;
  color: #8C898A;
  padding-bottom: 2px;
  border-bottom: 1px solid #8C898A;
  line-height: 0.9em;
}

.p-desc-list {

}

p.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 7; /* Adjust this value to your desired number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 19px;
}
p.truncate.learn {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust this value to your desired number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.p-info {
  font-weight: 400;
  padding: 0;
}
.p-label {
  color: black;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  margin-bottom: 0.6em;
}
.p-xsmall {
  color: black;
  font-size: 0.8em;
  font-weight: 400;
  width: fit-content;
}
.p-price {
  text-decoration: line-through #29ABE2;
  font-weight: 400;
}
.p-discount-price {
  background: #29ABE2;
  color: white;
  padding: 4px 12px 4px 12px;
  margin-right: -12px;
  margin-left: 12px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-pageinfo {
  color: #7C7A7A;
  font-size: 0.8em;
  font-weight: 300;
  width: fit-content;
}
.p-xlarge {
  color: black;
  font-size: 2.5em;
  font-weight: 300;
  margin: 0px;
}
.p-text {
  color: black;
  font-size: 1em;
  font-weight: 300;
  margin: 0px;
}
.p-normal {
  color: black;
  font-size: 14px;
  font-weight: 300;
  margin: 0px;
}
.p-doc {
  cursor: pointer;
  padding: 12px 12px;
  max-width: 180px;
}
.p-doc:hover {
  text-decoration: underline #707070;
}
.p-note {
    text-decoration: underline;
    margin-left: 4px;
    font-family: 'Courier New', monospace;
}
.icon-upload {

}
.row.docs {
  flex-wrap: wrap;
}
.upload-area {
  /*border: 2px solid #F0F0F0;*/
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}
.docs .upload-area {
  border: 2px solid #F0F0F0;
}
.upload-area:hover {
  border: 2px solid #C4C4C4;
}
.upload-area:hover img {
  transform: scale(1.03);
  transition: all 0.2s;
}
.upload-area img {
  object-fit: contain;
}
.upload-small {
  height: 100%;
  width: 100%;
  margin: 0;
}
.click {
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
}
.click:hover {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.03rem;
}
.click::before {
  display: block;
  content: attr(title);
  font-weight: 600;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
img.click:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.click-light:hover {
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -0.1px;
}
.click-light::before {
  display: block;
  content: attr(title);
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.promo-text {
  z-index: 999;
  color: white;
  font-size: 3em;
  line-height: 1.5em;
  margin-left: 36px;
  font-weight: 900;
  height: 350px;
  max-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  text-align: center;
  text-shadow: 0px 0px 180px rgba(0,0,0,0.55);
  overflow: hidden;
}


/* CORE */
.main {
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #F0F0F0;
}
.main.before:before {
  background: url('assets/img/background.jpg');
  content:'';
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  opacity: 0.58;
}

.page {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  padding: 0 15px 15px 15px;
  overflow-y: auto;
}
.page.relative {
  position: relative;
}
.page.relative .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.page.centered .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
.page.digital-menu .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  margin-top: 0px;
}
.container-column-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.container-column-2 .column-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 50%;
}

.container-row-2 .row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.container-row-2 .row-2 .row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 50%;
  justify-content: flex-start;
}
.container-row-2 .row-2 .row:first-child {
  padding-right: 32px;
}
.container-row-2 .row-2 form {
  width: 100%;
}
.container-row-2 .row-2 .row form {
  padding-left: 32px !important;
}
.container-row-2 .row-2 form:first-child, .container-column-2 .column-2:first-child {
  padding-right: 16px;
}
.container-row-2 .row-2 form:last-child, .container-column-2 .column-2:last-child, .container-row-2 .row-2 form:nth-child(3) {
  padding-left: 16px;
}
.icon.arrow {
  cursor: pointer;
}
.icon.arrow path{
  fill: #000;
}
.rotate {
  transform: rotate(
          180deg
  );
}
.custom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.custom-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-body .custom-row form:first-child {
  padding-right: 12px;
}
.modal-body .custom-row form:nth-child(3) {
  padding-left: 12px;
}


.title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  padding: 5px 20px;
  background: transparent;
  height: auto;
  width: auto;
  left: 12px;
  color: white;
  margin-top: 0px;
}

.title span  {
  -webkit-text-stroke: 1px black; /* For WebKit (Safari, Chrome, etc) */
  text-stroke: 1px black; /* Standard Syntax. Note: Not all browsers support this. */
}


/* Map styles */
.taxi {
  color: gold;
}
.zones {
  color: black;
  zoom: 0.68;
}
.leaflet-container {
  height: inherit;
  width: 100%;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}

/* Toolbar styles */
.toolbar {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  /*padding: 10px 0;*/
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.toolbar button{
  height: 30px;
  text-align: center;
  line-height: 0;
  padding: 0 8px;
  margin: 2px;
}


.right-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: auto;
  padding-right: 15px;

}

.checkbox-small {
  width: 22px;
  height: 22px;
}


/* Button & Custom File Button */
button {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.1s;
}

/* Custom icon for the map markers */
.custom-icon span {
  display: block;
  width: 12px;  /* Increased from 12px */
  height: 12px;  /* Increased from 12px */
  border-radius: 50%;
  transition: transform 0.3s ease;  /* <-- Add this line */
}

.editing-icon span {
  border: 2px dashed white;
  transform: scale(1.2);  /* Scales the icon up when editing */
}


/* Hide default file input */
input[type="file"] {
  display: none;
}

.map-and-sidebar {
  position: relative;
  height: calc(100vh - 84px); /* 100vh is the full viewport height */
}


.zone-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto; /* Match the height of your map */
  max-height: inherit;
  overflow-y: scroll;
  width: 200px;
  background: #f9f9f9;
  padding: 16px;
  border-left: 2px solid gray;
  z-index: 999;
}

.zone-list h3 {
  margin: 0 0 12px;
  font-size: 14px; /* Adjust font size to fit sidebar width */
}

.zone-list ul {
  padding: 0;
  margin: 0;
  list-style: upper-roman;
  text-align: left;
  padding-left: 12px;
}

.zone-list li {
  margin-bottom: 8px;
  font-size: 12px; /* Adjust font size to fit sidebar width */
  padding-left: 12px;
}
.zone-list button{
  width: 170px;
  height: 18px;
}

.leaflet-control-attribution {
  display: none;
}

.my-div-icon {
  background: transparent;
  border: none;
}


.divider {
  width: 68%;
  outline: none;
  border: none;
  background: #a0a0a0;
  height: 1px;
}

/* L10N */
.collapsible-header.l10n-options {
  right: 18px;
  position: absolute;
  top: 15px;
  cursor: pointer;
  zoom: 0.9;
}
.Collapsible__contentOuter {
  overflow: hidden;
  position: absolute;
  right: 17px;
  top: 56px;
}
.collapsible-header.l10n-options > svg {
  zoom: 0.75;
  padding-left: 5px;
  fill: white;
}
.l10n-options-content {
  zoom: 0.8;
  cursor: pointer;
}

.Collapsible__contentInner .country-label-container {
  padding-top: 3px;
}

/* FORM COLUMNS */

.form_col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.form-row form > label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.form-row form > label > p {
  margin-right: 40px;
  min-width: 14vw;
  max-width: 14vw;
}
.form-row form > label > input {
  width: 500px;
  margin-right: 20px;
  background: white;
}

