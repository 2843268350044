
.modal-overlay {
    background: rgba(255, 255, 255, 0.75);
    opacity: 1;
    position: absolute;
    top: 0;
    /*left: 390px;*/
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}



.modal {
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #F0F0F0;
    border-radius: 20px;
    opacity: 1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 945px;
}

.modal .modal-header {
    border-bottom: 1px solid #0A0A0A;
    padding-bottom: 20px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.modal .modal-header p {
    font-size: 30px;
    font-weight: bold;
    color: #0A0A0A;
}
.modal .modal-header .icon-close {
    font-size: 44px;
    color: #707070;
}
.modal form {
    width: 100%;
}
.modal .input-default {
    width: 100%;
}

/* modal add product */
.modal-product .modal-body-split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.modal-product .modal-body-split form:first-child {
    padding-right: 16px;
//margin-bottom: 16px !important;
}
.modal-product .modal-body-split form:last-child, .modal-product .modal-body-split form:nth-child(3) {
    padding-left: 16px;
//margin-bottom: 16px !important;
}

.modal-height {
    max-height: 95vh;
}
.modal-body {
    width: 100%;
    overflow-y: scroll;
}

/* SCROLL STYLING */

/*
.item-list.dynamic::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

.item-list.dynamic::-webkit-scrollbar-track:hover {
    background-color: #ccc;
}

.item-list.dynamic::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
    width: 40px;
}

.item-list.dynamic::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
*/

/* CREATE USER MODAL */
.modal-user-create {

}

/* MODAL INFO */

.modal-info .modal {
    min-width: 200px;
    max-width: 500px;
    width: auto;
}

.modal-info .modal-header {
    padding-bottom: 6px;
    margin-bottom: 0;
    border-bottom: 1px solid #afafaf !important;
}

.modal-info .modal-header p {
    font-size: 18px !important;
}

.modal-info .modal-header svg {
    zoom: 0.7;
    opacity: 0.7;
}

.modal-info-body {
    margin: 40px 0;
    margin-bottom: 24px;
}

.modal-info .modal-info-body p {
    font-size: 14px;
    font-weight: 500;
}

.notifications-list .modal-info {
    position: fixed;
    left: 0;
    top: 0;
    margin: auto;
}