.grouped-list-container {
    margin: 4px 0;
    width: 100%;
}

.grouped-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 100%;
    box-sizing: border-box;
}

.grouped-list-content {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 10px;
    flex: 1;
}

.grouped-list-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.grouped-list-children {
    margin-left: 20px;
    margin-top: 4px;
    width: calc(100% - 20px);
}

.grouped-list-arrow {
    cursor: pointer;
    user-select: none;
    transform: rotate(0deg);
    transition: transform 0.2s ease;
    font-size: 12px;
    width: 12px;
    display: inline-block;
}

.grouped-list-arrow.expanded {
    transform: rotate(90deg);
}

.grouped-list-checkbox {
    width: 13px;
    height: 13px;
    margin: 0;
    padding: 0;
}

.grouped-list {
    width: 100%;
}

.grouped-list-count {
    font-size: 12px;
    color: #666;
    margin-left: auto;
    padding-left: 8px;
}