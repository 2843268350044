.contact-form {
    margin: 0 auto;
    padding: 10px;
    max-width: 1200px;
}

.promo-ad-form-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 10px;
}

.promo-banners-column{
    position: relative;
}

.promo-banners-picker-container{
    position: relative;
}

.button-container-form {
    display: flex;
    justify-content: center;
    width: 100%;
}

h4 {
    margin-bottom: 10px;
    height: 22px;
}

.label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}

.double-input {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.input {
    width: 100%;
}

select {
    box-shadow: none;
}


